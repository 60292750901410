import { Col, Row } from "react-bootstrap";
import ConnectWalletButton from "./ConnectWalletButton";
import Logo from "./Logo";

import styles from "./Navbar.module.scss";


export const Navbar: any = () => {
    return (
        <Row
            className={`border-bottom pb-3 pt-3 justify-content-center ${styles.navBarContainer}`}
        >
            <div className={`${styles.navBar} d-flex justify-content-between`}>
                <Col xs={{ span: 5 }} sm={{ span: 3 }} md={{ span: 2 }}>
                    <Logo />
                </Col>
                <Col
                    className="pb-2 align-self-center pt-1"
                    xs={{ span: 7 }}
                    sm={{ span: 5, offset: 4 }}
                    md={{ span: 4, offset: 6 }}
                    lg={{ span: 2, offset: 0 }}
                >
                    <ConnectWalletButton showBalance={false} />
                </Col>
            </div>
        </Row>
    );
};
