import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { DAppProvider, Mainnet } from "@usedapp/core";

import App from "./components/App";
import "./index.module.scss";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: 'https://mainnet.infura.io/v3/263cd2cd601c4ee7a85348c349c49e97',
  },
};

Sentry.init({
  dsn: 'https://d160458c56124791bc420c63704f7b8a@o1281367.ingest.sentry.io/6499277',
  integrations: [new BrowserTracing()],
  environment: 'production',
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </DAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
