import { useEthers } from '@usedapp/core';
import { Navigate } from 'react-router-dom';

const WalletProtectedRoute = ({ children }: { children: JSX.Element }) => {

    const {account} = useEthers();

    if(account){
        return children;
    }

    return <Navigate to='/' replace />
}

export default WalletProtectedRoute;