import { Container } from "react-bootstrap";

import Navbar from './Navbar'
import styles from './styles.module.scss';

interface LayoutProps {
    showNavbar: boolean;
    children: React.ReactNode
}

export const Layout = ({ children, showNavbar, ...props }: LayoutProps) => {

    return (
        <div className={styles.layout}>
            {
                <Container fluid>
                    {showNavbar && (
                        <Navbar {...props} />
                    )}
                    <div className={styles.wrapper}>
                        {children}
                    </div>
                </Container>
            }
        </div>
    );
};
