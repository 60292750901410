import { Row, Col } from "react-bootstrap";
import ConnectWalletButton from "../Layout/Navbar/ConnectWalletButton";

import style from './Home.module.scss';

export function Home() {

  return (
    <Row>
      <Col>
        <div className="align-items-center d-flex flex-column justify-content-center">
          <Row className={style.octoImg}>
            <img src="/octo-main.png" alt="Genesis" />
          </Row>
          <Row>
            <div><h1 className={style.welcome}>Welcome to HeyEdu!</h1></div>
          </Row>
          <Row className={`${style.description} text-center pt-3`}>
            <div className="fs-4">To start using HeyEdu, just log in with your metamask wallet.</div>
          </Row>
          <Row className="pt-4">
            <ConnectWalletButton showBalance={false}/>
          </Row>
        </div>
      </Col>
    </Row>
  )
}