import { Route, Routes, } from "react-router-dom";

import Layout from "../Layout";
import Home from "../Home";
import NftHome from "../NftHome";
import WalletProtectedRoute from "../Auth/WalletProtectedRoute";
import { useEthers } from "@usedapp/core";
import { PreventIfWalletConnected } from "../Auth/PreventIfWalletConnected";

export const App = () => {
    const { account, deactivate, activateBrowserWallet } = useEthers();

    return (
        <Layout showNavbar>
            <Routes>
                <Route path='/' element={
                    <PreventIfWalletConnected>
                        <Home />
                    </PreventIfWalletConnected>} />
                <Route path='/nft-home/' element={
                    <WalletProtectedRoute>
                        <NftHome />
                    </WalletProtectedRoute>
                } />
            </Routes>
        </Layout>
    )
}
