import { Navigate } from 'react-router-dom';
import { useEthers } from '@usedapp/core';

export const PreventIfWalletConnected = ({ children }: { children: JSX.Element }) => {
  const {account} = useEthers();

  if(account){
    return <Navigate to='/nft-home/' replace />
  }

  return children;
}