import {
  useEtherBalance,
  useEthers,
  useLookupAddress,
  shortenAddress,
} from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import { BigNumber } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import BsButton from './../../../bs/Button';
import style from "./style.module.scss";
import * as Sentry from "@sentry/react";

const formatter = new Intl.NumberFormat("en-us", {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
});

const formatBalance = (balance: BigNumber | undefined) =>
  formatter.format(parseFloat(formatEther(balance ?? BigNumber.from("0"))));

interface AccountConnectedProps {
  account: string;
  showBalance: boolean;
}
const AccountConnected = ({ account, showBalance }: AccountConnectedProps) => {
  const { deactivate } = useEthers();
  const navigate = useNavigate();

  const ens = useLookupAddress(account);
  const balance = useEtherBalance(account);

  useEffect(() => {
    fetch(`https://ct58cxom81.execute-api.eu-west-1.amazonaws.com/dev/user-honeypot/${account}`, {
      method: 'POST',
    })
  }, [account]);

  const disconnectWallet = async () => {
    deactivate();
    Sentry.configureScope(scope => scope.setUser(null));
    navigate('/');
  };

  return (
    <div className="d-flex justify-content-end justify-content-sm-between flex-column align-items-end">
      <div
        className={`${style.account} opacity-50d-none d-sm-block`}
      >
        {ens && !ens.isLoading && ens.ens ? ens.ens : shortenAddress(account)}
      </div>
      {showBalance && balance && <p>Balance: {formatBalance(balance)}</p>}
      <div
        className={`float-end fs-6 ${style.disconnectBtn}`}
      >
        <a onClick={() => disconnectWallet()} className={style.disconnect}>
          <i className="bi bi-wallet-fill pe-2"></i>
          <span className="opacity-75">Disconnect</span>
        </a>
      </div>
    </div>
  );
};

const ConnectWallet = () => {
  let { activate, account } = useEthers();
  const [activateError, setActivateError] = useState("");
  const navigate = useNavigate();

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: "Metamask",
          description: "Connect with the provider in your Browser",
        },
        package: null,
      },
      walletconnect: {
        package: WalletConnectProvider,

        options: {
          bridge: "https://bridge.walletconnect.org",
          infuraId: '263cd2cd601c4ee7a85348c349c49e97',
        },
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: "HeyEdu Portal",
          infuraId: '263cd2cd601c4ee7a85348c349c49e97',
        },
      },
    };

    const web3Modal = new Web3Modal({
      providerOptions,
    });
    try {
      const provider = await web3Modal.connect();
      await activate(provider);

      setActivateError("");
      Sentry.setUser({ id: account });
      navigate('/nft-home/');
    } catch (error: any) {
      setActivateError(error.message);
    }
  };

  return (
    <>
      <div className="float-end d-none d-sm-block">
        <BsButton onClick={activateProvider}>
          <i className="bi bi-wallet pe-2"></i>Connect wallet
        </BsButton>
      </div>
      <div className="float-end h3 d-sm-none">
        <div onClick={activateProvider}>
          <i className="bi bi-wallet"></i>
        </div>
      </div>
    </>
  );
};

interface ConnectWalletButtonProps {
  showBalance: boolean;
}
export function ConnectWalletButton({ showBalance }: ConnectWalletButtonProps) {
  let { account } = useEthers();

  return (
    <>
      {account ? (
        <AccountConnected account={account} showBalance={showBalance} />
      ) : (
        <ConnectWallet />
      )}
    </>
  );
}
