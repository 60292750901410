import { Row, Col, Container, } from "react-bootstrap";

import styles from "./NftHome.module.scss";

export const NftHome = () => {
    
    return (
        <Row>
            <div className="pt-2 pt-lg-4">
                <Container fluid className={`${styles.solidSeparator} pe-0 ps-0 ps-sm-3 pe-sm-3`}>
                    <Col xs={12} md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
                        Złapany :)
                    </Col>
                </Container>
            </div>
        </Row>
    )
}